const getToken = () => {
  let arr = document.cookie.split(";");
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].indexOf("saber-access-token") !== -1) {
      var arr2 = arr[i].split('=');
      return arr2[1];
    }
  }
  return null;
}

const getHeaders = () => {
  return {
    "blade-auth": 'bearer ' + getToken()
  };
}

const getJsonHeaders = () => {
  return {
    "blade-auth": 'bearer ' + getToken(),
    "content-type": "application/json;charset=UTF-8"
  };
}

const getFileUrl = (name) => {
  return window.location.origin + "/" + name;
}

export {
  getToken,
  getHeaders,
  getJsonHeaders,
  getFileUrl
}