import {
    defineStore
} from 'pinia'
import {
    reactive
} from 'vue'
import {
    getNewsTable,
} from "@/api/index";
import _ from 'lodash'

const policyModularStore = defineStore('policyModular', {
    state: () => {
        const data = reactive({
            currentPage: 0,
            loadData: [],
            AllData: [],
            showRight: false,
            showLeft: false
        })
        return data
    },
    actions: {
        async setData(type, value) {
            switch (type) {
                case 'currentPage':
                    if (value == 'pre') {
                        this.currentPage -= 1
                    } else if (value == 'next') {
                        this.currentPage += 1
                    }
                    break
                case 'data':
                    this.AllData = value
                    break
                default:
                    break
            }
        },
        async reloadData() {
            this.loadData = _.chunk(this.AllData, 8)[this.currentPage]
            if (!_.chunk(this.AllData, 8)[this.currentPage + 1]) {
                this.showRight = false
            } else {
                this.showRight = true
            }
            if (this.currentPage != 0) {
                this.showLeft = true
            } else {
                this.showLeft = false
            }
        },
    }
})

export default policyModularStore