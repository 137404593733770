import {
    defineStore
} from 'pinia'
import {
    reactive
} from 'vue'
import {
    submitUserInfo,
    getImgCode,
    getNoticeById,
    getArticleDetailsById
} from "@/api/index";

const stateStore = defineStore('state', {
    state: () => {
        const data = reactive({
            EngineeringActive: 'tender_bulletin',
            PurchasingActive: 'tender_bulletin',
            BiddingTableActive: 'tender_bulletin',
            PurchasingTableActive: 'tender_bulletin',
            activeHtml: '',
            activeTitle: '',
            activePublishTime: '',
            activeItem: null,
            PurchasingcurrentPage: 0,
            EngineeringcurrentPage: 0,
            scrollX: 0,
            activeId: '',
            activeAttachList: [],
            files: [],
            activeType: '信息详情'
            // activeImg: '',
            // activeImgKey: ''
        })
        return data
    },
    actions: {
        async setEngineeringActive(value) {
            this.EngineeringActive = value
        },
        async setPurchasingActive(value) {
            this.PurchasingActive = value
        },
        async setData(type, value) {
            switch (type) {
                case 'activeType':
                    this.activeType = value
            }
        },
        async setactiveHtml(value, title, publishTime, item = '') {
            this.activeHtml = value
            this.activeTitle = title
            this.activePublishTime = publishTime
            this.activeItem = item
            // localStorage.setItem('activeHtml', this.activeHtml)
            // localStorage.setItem('activeTitle', this.activeTitle)
            // localStorage.setItem('activePublishTime', this.activePublishTime)
            // localStorage.setItem('activeItem', JSON.stringify(this.activeItem))
        },
        async selectNoticeById(value) {
            this.activeId = value
            const {
                data
            } = await getNoticeById({
                id: this.activeId
            })
            this.files = []
            this.activeHtml = data.content
            this.activeTitle = data.name
            this.activePublishTime = data.publishTime
            this.activeAttachList = data.attachList ? data.attachList : []
            let filesData = ''
            filesData = data.files;
            filesData = filesData.replace('[', '')
            filesData = filesData.replace(']', '')
            filesData = filesData.split('},')
            console.log('selectNoticeById', data)
            filesData.map((v, index) => {
                console.log(index, v, filesData, 'mapFilesData')
                if (filesData.length == index + 1) {
                    this.files.push(JSON.parse(v))
                } else {
                    this.files.push(JSON.parse(v + '}'))
                }

            })
            this.activeItem = data
            return new Promise((resolve, reject) => {
                resolve(this.activeType)
            })
        },
        async selectArticleById(value) {
            this.activeId = value
            const {
                data
            } = await getArticleDetailsById({
                id: this.activeId
            })
            this.activeHtml = data.content
            this.activeTitle = data.title
            // this.activePublishTime = data.publishTime
            this.activePublishTime = data.createTime
            this.activeAttachList = data.attachList ? data.attachList : []
            this.activeItem = data
            return new Promise((resolve) => {
                resolve(this.activeType)
            })
        },
        async preEngineeringcurrentPage() {
            if (this.EngineeringcurrentPage === 0) {
                this.EngineeringcurrentPage = 0
            } else {
                this.EngineeringcurrentPage--
            }
        },
        async nextEngineeringcurrentPage() {
            this.EngineeringcurrentPage++
        },
        async prePurchasingcurrentPage() {
            if (this.PurchasingcurrentPage === 0) {
                this.PurchasingcurrentPage = 0
            } else {
                this.PurchasingcurrentPage--
            }
        },
        async nextPurchasingcurrentPage() {
            this.PurchasingcurrentPage++
        },
        async clearCurrentPage(type) {
            if (type == 'Purchasing') {
                this.PurchasingcurrentPage = 0
            } else if (type == 'Engineering') {
                this.EngineeringcurrentPage = 0
            }
        },
        async setscrollX(value) {
            this.scrollX = value
        }
        // async setactiveImgCodeInfo() {
        //     const data = await getImgCode()
        //     this.activeImg = data.image
        //     this.activeImgKey = data.key
        // }
    }

})


export default stateStore