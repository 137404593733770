import axios from 'axios'
import { getToken } from '@/util/index'
import { ElMessageBox, ElMessage } from "element-plus";

const service = axios.create({
  // 配置
})
service.interceptors.request.use(
  req => {
    if (getToken()) {
      req.headers["blade-auth"] = "bearer " + getToken();
    }
    return req;
  },
  error => {
    Promise.reject(error);
  }

);
service.interceptors.response.use(
  res => {
    const code = res.code || 200;

    if (code === 401) {
      ElMessageBox.confirm(
        "您还未登录，是否跳转到登录页面？",
        'Warning',
      ).then(() => {
        window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
      });
    } else if (code === 500) {
      ElMessage.error('系统错误，请稍后重试！')
      return Promise.reject(new Error(res.data.msg));
    } else if (code !== 200) {
      ElMessage.error(res.data.msg)
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  error => {
    const status = error.response.status;
    if (status === 401) {
      ElMessageBox.confirm(
        "您还未登录，是否跳转到登录页面？",
      ).then(() => {
        window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
      });
      return Promise.reject(error);
    } else if (status === 400) {
      ElMessage.error(error.response.data.msg)
      return Promise.reject(error);
    }  else {
      ElMessage.error('系统错误，请稍后重试！')
      return Promise.reject(error);
    }
  }

);

export default service;