import request from "@/util/axios"

const getMemberBenefitType = () => {
  return request.request({
    url: "/api/fontController/fontController/getMemberBenefitsType",
    method: "GET"
  })
}

const getMemberLevelBenefits = () => {
  return request.request({
    url: "/api/fontController/fontController/getMemberLevelBenefits",
    method: "GET"
  })
}

const getCurrentMember = () => {
  return request.request({
    url: '/api/companyMember/getCurrentMember',
    method: 'GET',
  })
};

const pay = (params) => {
  return request.request({
    url: '/api/companyMember/order/pay',
    method: 'POST',
    data: params
  })
};
const checkOrderStatus = (params) => {
  return request({
    url: '/api/blade-pay/order/checkOrderStatus',
    method: 'GET',
    params
  })
};

export {
  getMemberBenefitType,
  getMemberLevelBenefits,
  getCurrentMember,
  pay,
  checkOrderStatus

}