import axios from 'axios'
// import {
//     Base_URL
// } from './static'



const request = axios.create()

// request.defaults.baseURL = Base_URL

// 响应拦截器
request.interceptors.response.use(response => {
    // return Promise.resolve(response.data)
    return response.data
}, error => {
    return Promise.reject(error)
});

export default request