<template>
  <router-view></router-view>
</template>

<script>
// import { getAllNav, getAllArticle, getAllAd } from "@/api/index";
import {
  mainStore,
  stateStore,
  BiddingTableStore,
  PurchasingTableStore,
  policyModularStore,
} from "@/store/index";
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
// import zhCn from "element-plus/lib/locale/lang/zh-cn";

export default {
  setup() {
    // const locale = zhCn;
    //   // getAllArticle({ id: "1556172416080781313" }).then((res) => {
    //   // });
    //   // getAllNav().then((res) => {
    //   // });
    //   // getAllAd().then((res) => {
    //   // });
    onBeforeMount(async () => {
      const route = useRoute();
      const store = mainStore();
      await store.initState();
      await BiddingTableStore().getClassfiyData();
      await PurchasingTableStore().getClassfiyData();
      await policyModularStore().setData(
        "data",
        storeToRefs(store).PolicyInfo.value
      );
      await policyModularStore().reloadData();
      if (route.path == "/BiddingInfo" || route.path == "/InfoView") {
        const title = await store.checkTitle(route.query.id);
        if (title) {
          document.title = title + "详情";
        } else if (route.query.type) {
          document.title = route.query.type;
        }
      }
    });
    const store = mainStore();
    // store.initState();
    const {
      navBarGetter,
      categoryNavGetter,
      AdDataGetter,
      ActiveArticleGetter,
    } = storeToRefs(store);
    return {
      navBarGetter,
      categoryNavGetter,
      AdDataGetter,
      ActiveArticleGetter,
      // locale,
    };
  },
};
</script>

<style>
.el-table tr {
  height: 80px !important;
}
.el-carousel__button {
  height: 8px !important;
  width: 8px !important;
  border-radius: 100% !important;
}
* {
  font-family: "思源黑体" !important;
}
</style>

