import {
    defineStore
} from 'pinia'



const DlogStore = defineStore('DlogStore', {
    state: () => ({
        left: 0,
        top: 0,

    }),
    actions: {
        setData(topValue, leftValue) {
            this.top = topValue
            this.left = leftValue
        }
    }

})

export default DlogStore