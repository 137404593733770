import {
    defineStore
} from 'pinia'
import {
    reactive
} from 'vue'
import {
    getNewsTable,
} from "@/api/index";


const NewsTableStore = defineStore('NewsTable', {
    state: () => {
        const data = reactive({
            total: 0,
            currentPage: 1,
            page: 1,
            size: 10,
            loadData: [],
            activeId: '',
            typeData: [],
            activeType: 'news'
        })
        return data
    },
    actions: {
        setData(type, value) {
            switch (type) {
                case 'total':
                    this.total = value
                    break
                case 'currentPage':
                    this.currentPage = value
                    break
                case 'page':
                    this.page = value
                    break
                case 'size':
                    this.size = value
                    break
                case 'data':
                    this.loadData = value
                    break
                case 'activeId':
                    this.activeId = value
                    break
                case 'typeData':
                    if (!this.activeId) {
                        this.activeId = value[0].id
                        // if (this.activeType == 'news') {
                        //     const obj = value.find(v => v.title.indexOf('新闻') > -1)
                        //     console.log(obj, 'obj', value)
                        //     this.activeId = obj.id
                        // } else if (this.activeType) {
                        //     const obj = value.find(v => v.title.indexOf('政策') > -1)
                        //     console.log(obj, 'obj', value)
                        //     this.activeId = obj.id
                        // }
                        // this.activeType = ''
                    }
                    this.typeData = value
                    break
                case 'activeType':
                    this.activeType = value
                    break
                default:
                    break
            }
        },
        async reloadTableData() {
            const res = await getNewsTable({
                current: this.page,
                size: this.size,
                // id: this.activeId
                id: this.activeId
            });
            this.setData("total", res.data.total);
            this.setData("data", res.data.records);
        },
    }
})

export default NewsTableStore