import {
    defineStore
} from 'pinia'
import {
    reactive
} from 'vue'
import {
    getTableList,
    getClassfiyCode
} from "@/api/index";
import _ from 'lodash'

const PurchasingTableStore = defineStore('PurchasingTable', {
    state: () => {
        const data = reactive({
            total: 0,
            currentPage: 1,
            page: 1,
            size: 10,
            loadData: [],
            type: '', //tender_bulletin
            descs: 'publish_time',
            ascs: '',
            projectTypeData: [],
            tenderProjectClassifyCodeSecond: '',
            name: '',
            tradeClassification: ''
        })
        return data
    },
    actions: {
        async setData(type, value) {
            switch (type) {
                case 'total':
                    this.total = value
                    break
                case 'currentPage':
                    this.currentPage = value
                    break
                case 'page':
                    this.page = value
                    break
                case 'size':
                    this.size = value
                    break
                case 'data':
                    this.loadData = value
                    break
                case 'type':
                    this.type = value
                    break
                case 'tenderProjectClassifyCodeSecond':
                    this.tenderProjectClassifyCodeSecond = value
                    break
                case 'descs':
                    this.descs = value
                    break
                case 'ascs':
                    this.ascs = value
                    break
                case "name":
                    this.name = value
                    break
                case "tradeClassification":
                    this.tradeClassification = value
                    break
                default:
                    break
            }
        },
        async reloadTableData() {
            const res = await getTableList({
                current: this.page,
                size: this.size,
                // tenderProjectClassifyCodeFirst: ["B", 'C'],
                tenderProjectClassifyCodeSecond: this.tenderProjectClassifyCodeSecond == 'all' ? '' : this.tenderProjectClassifyCodeSecond,
                type: this.type,
                descs: this.descs,
                ascs: this.ascs,
                name: this.name,
                tradeClassification: this.tradeClassification
            });
            this.setData("total", res.data.total);
            this.setData("data", res.data.records);
        },
        async getClassfiyData() {
            let res = await getClassfiyCode({
                dictKey: ['A', 'B', 'C']
            })
            let typeData = res.data
            typeData.unshift({
                dictKey: 'all',
                dictValue: '全部'
            })
            this.projectTypeData = _.chunk(typeData, 12)
        }
    }
})

export default PurchasingTableStore