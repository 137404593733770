// const commonDir = '@/static/common'

const leftNavImg = {
    leftNavImg: require(`@/static/common/组 1.png`),
    leftNavIconOfsupplier: require(`@/static/common/gys2x.png`),
    leftNavIconOfAgency: require(`@/static/common/组 8723.png`),
    leftNavIconOfRegiter: require(`@/static/common/zjpw2x.png`),
    leftNavIconOfExpert: require(`@/static/common/组 8722.png`),
    leftNavIconOfTenderee: require(`@/static/common/组 8721.png`),
    leftNavIconOfDirect: require(`@/static/common/组 8720.png`),
    leftNavIconOfLogin: require(`@/static/common/zjLogin.png`),
}

const headerImg = {
    logoImg: require(`@/static/common/三源logo.png`),
    searchIcon: require(`@/static/common/搜索.png`),
    noticeIcon: require(`@/static/common/消息 (1).png`),
    activeLiIcon: require(`@/static/common/矩形 122.png`),
}

const footerImg = {
    footerBg: require(`@/static/common/组 8764.png`),
    footerPhone: require(`@/static/common/图层 1.png`),
    footerAdress: require(`@/static/common/地址图标.png`),
    footerEmail: require(`@/static/common/邮箱图标.png`),
}

const rightNavImg = {
    rightCA: require(`@/static/common/组 8763.png`),
    rightTeach: require(`@/static/common/网络教学安排.png`),
    rightPhoe: require(`@/static/common/phone3.png`),
    rightDown: require(`@/static/common/下载-文件下载-07.png`),
    rightUp: require(`@/static/common/返回顶部.png`)
}

const modularImg = {
    Left: require('@/static/index/Purchasing/组 8757（left）.png'),
    Right: require('@/static/index/Purchasing/组 8757.png'),
    rightArrow: require('@/static/common/箭头_向右 (1).png')
}



const indexImg = {
    indexBannerImg: require(`@/static/common/banner.png`),
    indexAdImg: require('@/static/index/组 8758.png'),
    serviceBg: require('@/static/common/Service_bg.png')
}

const PurchasingImg = {
    Item1: require('@/static/index/Purchasing/蒙版组 26.png'),
    Item2: require('@/static/index/Purchasing/蒙版组 27.png'),
    Item3: require('@/static/index/Purchasing/蒙版组 24.png'),
    Item4: require('@/static/index/Purchasing/蒙版组 28.png'),
}

const EngineeringImg = {
    Item1: require('@/static/index/Engineering/蒙版组 29.png'),
    Item2: require('@/static/index/Engineering/蒙版组 30.png'),
    Item3: require('@/static/index/Engineering/蒙版组 31.png'),
    Item4: require('@/static/index/Engineering/蒙版组 32.png'),
}

const HelpCenterImg = {
    HelpCenterImg: require('@/static/index/HelpCenter/组 8760.png'),
    HelpCenterDown: require('@/static/index/HelpCenter/下载-文件下载-07.png')
}

const BiddingImg = {
    BiddingbannerImg: require('@/static/Bidding/组 9135.png')
}

const FinancialImg = {
    banner: require('@/static/Financial/组 8765.png'),
    // introduce1: require('@/static/Financial/01.png'),
    // introduce2: require('@/static/Financial/02.png'),
    // introduce3: require('@/static/Financial/03.png'),
    // introduce4: require('@/static/Financial/04.png'),
    // introduce5: require('@/static/Financial/05.png'),
    introduce1: require('@/static/Financial/1.jpg'),
    introduce2: require('@/static/Financial/2.jpg'),
    introduce3: require('@/static/Financial/3.jpg'),
    introduce4: require('@/static/Financial/4.jpg'),
}

const NewsCenterImg = {
    banner: require('@/static/NewsCenter/组 8766.png'),
    NewsCenterLeft: require('@/static/NewsCenter/组 9136.png'),
    NewsCenterRight: {
        top: {
            item1: require('@/static/NewsCenter/bg.png'),
            item2: require('@/static/NewsCenter/bg 拷贝.png'),
        },
        bottom: {
            item3: require('@/static/NewsCenter/bg 拷贝 2.png'),
            item4: require('@/static/NewsCenter/bg 拷贝 2(1).png'),
        }
    },
    policy: {
        item1: require('@/static/NewsCenter/pexels-aleksejs-bergmanis-681335.png'),
        item2: require('@/static/NewsCenter/david-martin-p9vBVq_-nXY-unsplash.png'),
        item3: require('@/static/NewsCenter/gordon-mak-rX2cj2FD4do-unsplash.png'),
        item4: require('@/static/NewsCenter/pexels-pixabay-220762.png'),
        item5: require('@/static/NewsCenter/kimi-lee-M3RVFbqKGu4-unsplash.png'),
        item6: require('@/static/NewsCenter/pexels-quietbits-4424369.png'),
        item7: require('@/static/NewsCenter/nagy-szabi-RsjQl8dLnfw-unsplash.png'),
        item8: require('@/static/NewsCenter/pexels-pixabay-208745.png'),
    },
    policyRight: require('@/static/NewsCenter/向右3-fill.png')
}

const ContactImg = {
    banner: require('@/static/Contect/组 8767.png'),
    content: {
        item1: require('@/static/Contect/图像 110.png'),
        item2: require('@/static/Contect/图像 111.png'),
    }
}


const EquityImg = {
    banner: require('@/static/Equity/组 9076.png')
}


const BiddingInfoImg = {
    banner: require('@/static/BiddingInfo/bannner .png'),
    contentCrumbs: {
        backIcon: require('@/static/BiddingInfo/返回.png'),
        timeIcon: require('@/static/BiddingInfo/时间 (3).png'),
        joinIcon: require('@/static/BiddingInfo/参与项目.png'),
    },
    contentIcon: require('@/static/BiddingInfo/组 9134.png'),
}

const categoryImg = {
    engineering: require('@/static/Category/工程类公告.png'), //工程类
    business: require('@/static/Category/工商管理.png'), //工商管理：管理类 
    supervise: require('@/static/Category/监理.png'), //监理：带着工程帽类
    material: require('@/static/Category/建筑材料.png'), //建筑材料：建材类
    survey: require('@/static/Category/勘察.png'),
    investigation: require('@/static/Category/勘察与调查.png'),
    other: require('@/static/Category/其他服务.png'),
    design: require('@/static/Category/设计.png'),
    news: require('@/static/Category/新闻中心.png'),
    biddingAgency: require('@/static/Category/招标代理服务.png'),
    plan: require('@/static/Category/规划.png'),
    construction: require('@/static/Category/工程施工.png'),
    medical: require('@/static/Category/医疗器械.png'),
    petroleum: require('@/static/Category/石油及其制品.png'),

    Landscaping: require('@/static/Category/园林绿化.png'),
    Landscaping2: require('@/static/Category/园林绿化2.png'),
    road: require('@/static/Category/道路工程.png'),
    Bridge: require('@/static/Category/桥梁工程.png'),

    Chemical: require('@/static/Category/化工材料.png'),
    Repair: require('@/static/Category/修理.png'),
    Mechanical: require('@/static/Category/机械、设备类.png'),

}
const index_newsCenter = [
    require('@/static/index/NewsCenter/7.png'),
    require('@/static/index/NewsCenter/8.png'),
    require('@/static/index/NewsCenter/9.png'),
    // require('@/static/index/NewsCenter/3.png'),
    // require('@/static/index/NewsCenter/4.png'),
    // require('@/static/index/NewsCenter/5.png'),
]


const imgData = {
    ...headerImg,
    ...leftNavImg,
    ...footerImg,
    ...indexImg,
    ...rightNavImg,
    ...HelpCenterImg,
    modularImg,
    PurchasingImg,
    EngineeringImg,
    ...BiddingImg,
    FinancialImg,
    NewsCenterImg,
    ContactImg,
    EquityImg,
    BiddingInfoImg,
    categoryImg,
    index_newsCenter
}



export default imgData